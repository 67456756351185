import React, { ReactElement, useRef } from 'react';
import { TwoColSection } from '../../components/TwoColSection/TwoColSection';
import * as Styled from './styles';
import { css } from '@emotion/react';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { breakpoints, dimensions } from '../../styles/variables';
import { Feature } from "../Feature/Feature";
import { FeatureParagraphCrown, FeatureParagraphMark, FeatureTitleSwirl,} from "../../styles/styles";

import { useLocation } from '@reach/router';

interface SecretSauceProps {
 
  grayBottom: boolean; 
}

export const SecretSauce = ({ grayBottom }: SecretSauceProps): ReactElement => {
  const secretSauceRef = useRef(null);
  const { isVisible } = useVisibilityDetection(secretSauceRef);
  const location = useLocation();

  const showAll = false; //(location.pathname != '/sale/' && location.pathname != '/sale') 
  const isSale = true; // (location.pathname == '/sale-test/' || location.pathname == '/sale-test')  
  //console.log(showAll)
    return (
      <Styled.SafariWrapper
        styles={css`
          ${isSale &&
            `
              margin-top: -50px !important;
            `
          }
          `
          }
      >
        <Styled.Wrapper ref={secretSauceRef} isVisible={isVisible}  grayBottom={grayBottom}> 
       { showAll &&
      <TwoColSection
        styles={css`
          
          max-width: 1170px;
          margin-top: 0px;

          

          padding: 0px;

          /* opacity: 0;
          transform: translateY(10%);
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          ${isVisible &&
            `
              opacity: 1;
              transform: translateY(0);
            `}*/

          @media screen and (max-width: ${breakpoints.xl}px) {
            padding:0 30px;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            margin-top: 40px;
           
          }

          
        `}
        heading={
          <Styled.Heading  css={css`
                                width: 80%;
                                margin: auto;
                                @media screen and (max-width: ${breakpoints.md}px) {
                                  width: 100%;
                                }
                              `}>
            <Styled.HeadingTitle>Let SketchWow Be Your Secret-Sauce For Simple, Stunning-Sketches... So You Can Clearly & Casually Explain Anything</Styled.HeadingTitle>
          </Styled.Heading>
        }

        leftColumn={
          <Styled.LeftColumn css={css`
          padding: 0px;
          margin-left: -100px;
          width: 80%;
          @media screen and (min-width: 1301px) and (max-width: 1360px) {
            margin-left: -60px;
            width:90%;
          }
          @media screen and (min-width: 1280px) and (max-width: 1300px) {
            margin-left: -50px;
            width:90%;
          }
          @media screen and (max-width: 1279px) {
            margin-left: 0px;
            width:90%;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            width:100%;
            text-align:center;
          }

        `}>
            
              <Styled.Illustration css={css`
                @media screen and (max-width: ${breakpoints.xl}px) {
                  max-width:100%;
                }   
                @media screen and (max-width: ${breakpoints.lg}px) {
                  margin-top:30px;
                }
              `}
                src="/salespage/2nd section/visually-explain-anything2.png"
                alt="Illustration"
                loading="lazy"
                width={650}
              />
            
          </Styled.LeftColumn>
        }
        rightColumn={
          <Styled.RightColumn>
            <Styled.Content>
              <Styled.CheckList><strong>Easy-To-Use,</strong> Drag-And-Drop Interface</Styled.CheckList>
              <Styled.CheckList>Access <strong>Hundreds Of Handdrawn Style Shapes & Icons</strong></Styled.CheckList>
              <Styled.CheckList>Add <strong>Animated Icons & Lines</strong> For Extra Impact</Styled.CheckList>
              <Styled.CheckList>Create <strong>Dozens Of Different Design Types</strong></Styled.CheckList>
              <Styled.CheckList>Includes <strong>70+ Beautiful Templates</strong></Styled.CheckList>
              <Styled.CheckList><strong>30 Fonts</strong> (Add Custom Fonts Too)</Styled.CheckList>
              <Styled.CheckList><strong>Multi-language</strong> Text Support</Styled.CheckList>
              <Styled.CheckList><strong>Import And Markup</strong> Any Image Or Photo</Styled.CheckList>
              <Styled.CheckList><strong>PC Aand Mac</strong> Compatible</Styled.CheckList>
              <Styled.CheckList>Use It On <strong>All Your Computers</strong></Styled.CheckList>
              <Styled.CheckList>Includes <strong>Free Software Updates</strong></Styled.CheckList>

            </Styled.Content>
          </Styled.RightColumn>
        }
      />
    }
    { showAll &&
      <Styled.PenScribble/>
    }


      <TwoColSection
        styles={css`
   
          ${isSale &&
            `
              margin-top: -20px !important;
            `
          }
          
          max-width: 1170px;
          margin-top: 0px;
          padding: 0px;

          /*opacity: 0;
          transform: translateY(10%);
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          ${isVisible &&
            `
              opacity: 1;
              transform: translateY(0);
            `}*/

          @media screen and (max-width: ${breakpoints.md}px) {
            margin-top: 40px;
            padding:0 30px;
          }

          
        `}
        // heading={ !showAll &&
        //   <Styled.Heading  css={css`
        //                         width: 80%;
        //                         margin: auto;
        //                         @media screen and (max-width: ${breakpoints.md}px) {
        //                           width: 100%;
        //                         }
        //                       `}>
        //     <Styled.HeadingTitle>Let SketchWow Be Your Secret-Sauce For Simple, Stunning-Sketches... So You Can Clearly & Casually Explain Anything</Styled.HeadingTitle>
        //   </Styled.Heading>
        // }
        leftColumn={
          <Styled.LeftColumn css={css`
          padding: 0px;
          margin-left: -100px;
          @media screen and (min-width: 1301px) and (max-width: 1360px) {
            margin-left: -60px;
          }
          @media screen and (min-width: 1280px) and (max-width: 1300px) {
            margin-left: -50px;
          }
          @media screen and (max-width: 1279px) {
            margin-left: 0px;
          }
          @media screen and (max-width: ${breakpoints.lg}px) {
            margin-top: 40px;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            text-align:center;
          }
        `}>
            
              <Styled.Illustration css={css`
          @media screen and (max-width: ${breakpoints.xl}px) {
            max-width:100%;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            max-width:80%;
          }
        `}
                src="/salespage/2nd section/pyramid-chart2.svg"
                alt="Illustration"
                loading="lazy"
              />
            
          </Styled.LeftColumn>
        }
        rightColumn={
          <Styled.RightColumn>
            <Styled.Content>
            { /*
              <Styled.SubTitle css={css`font-size: ${dimensions.fontSize.heading}px; line-height: ${dimensions.lineHeight.heading};`}>
              
                <span css={FeatureTitleSwirl}>How</span> Can You Stand Out & Get Results With AN Overlay-Distracted Audience
              
                <span css={FeatureTitleSwirl}>Nobody</span> wants boring diagrams...
                </Styled.SubTitle>  */ }
              
              <Feature css={css`font-size: 15px; ; padding: 0px;`}
                  content={
                    <>
                      <p>
                        Let’s face it. Most sketches, flowcharts and diagrams are trapped
                        in 2002.
                      </p>
                      <p>
                        It’s a sea of <span css={FeatureParagraphMark}>sameness</span>…
                      </p>
                      <p>Forgettable… uninspiring. </p>

                      { /*
                      <p>Combined with the fact that attention spans have hit rock-bottom…</p>

                      <p>Can you see the problem?</p>

                      <p>It's becoming increasingly difficult to not get ignored.</p>*/ }

                      <p>
                        Next time you want to brainstorm or map out your thoughts… make it
                        memorable.
                        <br />
                        <br />
                        How? Use <strong css={FeatureParagraphCrown}>SketchWow...</strong>
                      </p>
                    </>
                  }

                  title={
                    <>
                      <span css={FeatureTitleSwirl}>Nobody</span> wants <br />
                      boring diagrams...
                    </>
                  }
                    
                />
            </Styled.Content>
          </Styled.RightColumn>
        }
      />

      <br/> <br/>

      <TwoColSection
        styles={css`
          
          max-width: 1170px;
          margin-top: 0px;
          padding: 0px;

          /* opacity: 0;
          transform: translateY(10%);
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          ${isVisible &&
            `
              opacity: 1;
              transform: translateY(0);
            `}*/


          @media screen and (max-width: ${breakpoints.lg}px) {
            padding:0 30px;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            margin-top: 40px;
            
          }

          
        `}

        leftColumn={
          <Styled.LeftColumn >          
            
            <Feature css={css`font-size: 15px; padding: 0px;              
            `}
                content={
                  <>
                    <p>
                      SketchWow is the perfect creative companion - so you can quickly
                      condense and communicate your ideas, concepts and stories (in a
                      clear and casual way)…. using a fresh & engaging,{" "}
                      <span css={FeatureParagraphMark}>hand drawn</span> look.
                      <br />
                      <br />
                      Once you use <strong>SketchWow…</strong>
                      <br />
                      <br />
                      You’ll never create a typical DIE-a-gram or white-BORED ever
                      again!
                    </p>
                  </>
                }
              
                title="What makes SketchWow refreshingly different?"
              />
          </Styled.LeftColumn>
        }
        rightColumn={
          <Styled.RightColumn css={css`         
          @media screen and (max-width: ${breakpoints.md}px) {
           text-align:center;
          }
        `}
          >
            <Styled.Content>

            {!showAll && 
            <Styled.Illustration css={css`  
                                          margin-right: -50px;    
                                          
                                          @media only screen and (max-width: ${breakpoints.xl}px) {
                                            width:550px;
                                            margin-right: 10px;
                                          }   
                                          @media screen and (max-width: ${breakpoints.md}px) {
                                            max-width:80%;
                                          }

                                        `}
        
                src="/salespage/2nd section/visually-explain-anything3.png"
                alt="Illustration"
                loading="lazy"

              />
             }
            {showAll && 
            <Styled.Illustration css={css`         
                                          @media screen and (max-width: ${breakpoints.md}px) {
                                            max-width:80%;
                                          }
                                        `}
        
                src="/salespage/2nd section/Easy-visuals-tb.png"
                alt="Illustration"
                loading="lazy"

              />
             }

            </Styled.Content>
          </Styled.RightColumn>
        }
      />

{ showAll &&

        <Styled.SWFeatures>
          
          <div css={css` 
           @media screen and (max-width: 1279px) {
            text-align:center;
           }
          `}>
            <Styled.Illustration
                src="/salespage/2nd section/SW-versus-everybody.png"
                alt="SketchWow Features"
                loading="lazy"
                css={css`
                  margin-top: -100px !important;
                  width: 1100px;
                  @media screen and (max-width: 1279px) {
                    width:90%;
                  }
                  @media screen and (max-width: ${breakpoints.xl}px) {
                    width:100%;
                    margin-top: -30px !important;
                  }
                  @media screen and (max-width: 599px) {
                    margin-top: 0px !important;

                  }
                  
                `}
              />
          </div>
        </Styled.SWFeatures>
              }
        </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};











